import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {partialUpdateField,retrieveCard} from "../http/fieldsApi";
import FormFix from "./Form_fix";
import { getIcon } from '../utils/getIcon';
import { htmlPurify } from '../utils/htmlPurify';
import { getColors } from "../utils/getColors";
async function rebase(id, list, startIndex, endIndex) {
	await partialUpdateField(list[startIndex].id, { order: endIndex });
	await retrieveCard(id);
}

const reorder = (id, list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	rebase(id, list, startIndex, endIndex);
	return result;
};
class Fields extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.id,
			fields: props.fields,
			isAuth: props.isAuth,
		};

		this.onDragEnd = this.onDragEnd.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				isAuth: nextProps.isAuth,
			});
		}
	}
	onDragEnd(result) {
		if (!result.destination) {
			return;
		}
		const fields = reorder(
			this.state.id,
			this.state.fields,
			result.source.index,
			result.destination.index
		);
		this.setState({
			fields: fields,
		});
	}
	componentDidMount() {
		// Задает цвет иконки
		if (this.props && this.props.fields && this.props.fields.length) {
			this.props.fields.forEach((item) => {
				const id = `${item.title}${item.id}`;
				const svgElement = document.getElementById(id);
				if (svgElement) {
					const path = svgElement.querySelectorAll('path');
					const polygon = svgElement.querySelectorAll('polygon');
					if (path && path.length) {
						path.forEach((svgLine) => {
							svgLine.style.fill = item.color_text;
						});
					}
					if (polygon && polygon.length) {
						polygon.forEach((svgLine) => {
							svgLine.style.fill = item.color_text;
						});
					}
				}
			});
		}
	}
	render() {
		return (
			<div>
				{this.state.isAuth ? (
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided) => (
								<div
									className="fields-container"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{this.state.fields?.map((element, index) => (
										<Draggable
											key={element.id}
											draggableId={element.id.toString()}
											index={index}
										>
											{(provided) => (
												<div
													className={
														'fix burger row d-flex align-items-center justify-content-center'
													}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<FormFix props={element} />
													<a
														href={element.link}
														className={`col-8 col-sm-7 col-lg-6 ${
															element.title.includes('line')
																? element.title
																: 'field-button'
														} ${
															element.title === 'text'
																? 'text-field-button'
																: ''
														}`}
														style={{
															backgroundColor: !!element.color_button
																? element.color_button : getColors(element.title),
															color: !!element.color_text
																? element.color_text : element.title === 'text' ? "#000000"
																: '#ffffff',
														}}
													>
														{element.title !== 'text' &&
														element.title !== 'line-4' &&
														element.title !== 'line-3' &&
														element.title !== 'line-2' &&
														element.title !== 'line-1' ? (
															<div className="d-flex align-content-center ">
																<div className="d-flex icons">
																	{getIcon(
																		element.title,
																		`${element.title + element.id}`
																	)}
																</div>
																<div className="d-flex element-text flex-column justify-content-center">
																	<div>{element.value}</div>
																</div>
															</div>
														) : element.title === 'text' ? (
															<div className="d-flex flex-column align-content-start justify-content-start">
																<div
																	className="text-button-container"
																	dangerouslySetInnerHTML={htmlPurify(
																		element.value
																	)}
																></div>
															</div>
														) : (
															<div className="d-flex flex-column align-content-start justify-content-start">
																<div>{element.value}</div>
															</div>
														)}
													</a>
													<div className="col-2 col-lg-1 d-flex justify-content-end">
														<svg
															className="burger_svg"
															viewBox="0 0 100 80"
															width="21"
															height="21"
														>
															<rect width="100" height="7">
																{' '}
															</rect>
															<rect y="30" width="100" height="7">
																{' '}
															</rect>
															<rect y="60" width="100" height="7">
																{' '}
															</rect>
														</svg>
													</div>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				) : (
					<div className="fields-container">
						{this.state.fields?.map((element) => (
							<div
								key={element.id}
								className="row d-flex align-items-center justify-content-center"
							>
								<a
									href={element.link}
									className={`${element.title} col-9 col-sm-7 col-lg-6 ${
										element.title === 'text' ? ' text_field' : ''
									} ${element.title === 'text' ? 'text-field-button' : ''}`}
									style={{
										backgroundColor: element.color_button
											? element.color_button
											: getColors(element.title),
										color: element.color_text ? element.color_text : element.title === 'text' ? '#000000' : '#FFFFFF',
									}}
								>
									{element.title !== 'text' &&
									element.title !== 'line-4' &&
									element.title !== 'line-3' &&
									element.title !== 'line-2' &&
									element.title !== 'line-1' ? (
										<div className="d-flex align-content-center">
											<div className="d-flex icons">
												<img
													className={'block-svg ' + element.title + '-svg'}
													src={'/image/' + element.title + '.svg'}
													alt=""
												/>
											</div>
											<div className="d-flex element-text flex-column justify-content-center">
												<div>{element.value}</div>
											</div>
										</div>
									) : element.title === 'text' ? (
										<div className="d-flex align-content-start justify-content-start">
											<div
												className="text-button-container"
												dangerouslySetInnerHTML={htmlPurify(element.value)}
											></div>
										</div>
									) : (
										<div className="d-flex flex-column align-content-start justify-content-start">
											<div>{element.value}</div>
										</div>
									)}
								</a>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}
export default Fields;