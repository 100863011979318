export const getIcon = (elementTitle) => {
	if (!elementTitle) {
		return null;
	}
	return (
		<img
			className={'block-svg ' + elementTitle + '-svg'}
			src={'/image/' + elementTitle + '.svg'}
			alt=""
		/>
	);
};
