import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';

const ImageModal = ({ avatar, setCroppedImage, submitImage }) => {
  const [zoom, setZoom] = useState(1);

  const avatarRef = useRef();

  function dataURItoBlob(dataurl, filename) {
    const fileExt = dataurl.split(';')[0].split('/')[1];
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], `${filename}.${fileExt}`, { type: mime });
  }
  
 const handleCrop = () => {
		if (avatarRef.current) {
			const canvasScaled = avatarRef.current.getImageScaledToCanvas();
      const img = canvasScaled.toDataURL();
			const file = dataURItoBlob(img, 'avatar');
			setCroppedImage(file);
		}
 };
	return (
		<div
			className="modal fade"
			id="exampleModal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">
							Modal title
						</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<AvatarEditor
							className="canvas_avatar"
							ref={avatarRef}
							image={avatar}
							width={250}
							height={250}
							border={50}
							borderRadius={200}
							color={[0, 0, 0, 0.6]} // RGBA
							scale={zoom}
							style={{ width: '100%', height: 'auto' }}
							rotate={0}
							onImageChange={handleCrop}
						/>
						<input
							type="range"
							value={zoom}
							min={1}
							max={3}
							step={0.1}
							onChange={(e) => setZoom(parseFloat(e.target.value))}
						/>
					</div>
					<div className="modal-footer">
						<button
							onClick={() => submitImage()}
							type="button"
							className="btn btn-primary"
							data-dismiss="modal"
						>
							Сохранить
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImageModal;
