// Удаление опасных тегов из html контента
import DOMPurify from 'dompurify';

export function htmlPurify(HTMLstring) {
	if (!HTMLstring) {
		return { __html: '' };
	} else {
		const pure = DOMPurify.sanitize(HTMLstring);
		return { __html: pure };
	}
}
