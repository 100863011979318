export function vCardSave(userData, phone, image) {
	const name = userData.displayed_name;
	const text = userData.fields && userData.fields.length
		? userData.fields
			.filter((item) => item.title === 'text') : [];
	const textResult = text && text.length ? text.length > 1 ? text.map(item => item.value).join('; ') : text[0].value : '';
	const textWithNoHTML = textResult
		.replaceAll(/<\/?[^>]+(>|$)/g, '')
		.replaceAll('\n', ' ').replaceAll('&nbsp;', ' ');
	const email = userData.fields && userData.fields.length
		? userData.fields.find((item) => item.title === 'email')?.link.slice(7)
		: '';
	const url = `${window.location.href}`;
	const vcardtext = `BEGIN:VCARD
VERSION:3.0
FN:${name}
${email && `EMAIL;type=INTERNET;type=pref:${email}`}
${phone && `TEL;type=CELL;type=VOICE;type=pref:${phone}`}
${image && `PHOTO;ENCODING=b;TYPE=${image}`}
${url && `URL;type=pref:${url}`}
${textWithNoHTML && `NOTE:${textWithNoHTML}`}
END:VCARD
`
		.replaceAll('null','')
		.replaceAll('undefined','')
		.replaceAll('\n\n', '\n')
		.replaceAll('\n\n', '\n')
		.replaceAll('\n\n', '\n')
		.replaceAll('\n\n', '\n');
const file = new Blob([vcardtext], { type: 'text/vcard;charset=utf-8' });
	let a = document.createElement('a');
	a.download = `touchipVcard.vcf`;
	a.href = URL.createObjectURL(file);
	var reader = new FileReader();
	if (navigator.userAgent.match(/android|ipad|iphone/i)) {
		reader.onload = (e) => {
			window.location.href = reader.result;
		};
		reader.readAsDataURL(file);
	} else {
		a.click();
	}
}