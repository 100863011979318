import React, { useEffect, useState } from 'react';
import { canChangeLog, changeLog } from '../http/userAPI';
import { partialUpdateCard } from '../http/fieldsApi';
import { useHistory } from 'react-router-dom';

const LogModal = ({ id, setChangedLogin, name }) => {
	const [value, setValue] = useState('');
	const [step, setStep] = useState(1);
	const history = useHistory();
	useEffect(() => {
		const btn = document.getElementById('logmodalbtn');
		btn && btn.click();
		const btn2 = document.getElementById('logmodalbtn2');
		btn2 && btn2.click();
	}, [id, step]);

	function handleValue(event) {
		setValue(event.target.value);
	}
	async function handleSubmit() {
		if (value === '') {
			//пароль решили не менять
			await partialUpdateCard(id, { has_changed_username: 'true' });
			document.getElementById('logmodalbtn').click();
			setChangedLogin();
			return
		} else {
			let response = await canChangeLog({ username: value });
			if (response.username_status === 'taken') {
				//если занят, то закончим функцию, и выведем, что занято
				return alert('логин уже занят, введите другой');
			}
		}
		await partialUpdateCard(id, { has_changed_username: 'true' });
		await changeLog({ username: value });
		history.push(`/${value}`);
		window.location.reload();
		return alert('Логин сохранен');
	}

	return (
		<>
			{step === 1 && (
				<div>
					<button
						type="button"
						id={'logmodalbtn'}
						className="btn btn-primary d-none"
						data-toggle="modal"
						data-target="#logModal"
					></button>
					<div
						className="modal fade"
						id="logModal"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="logModalLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog login-modal-one" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="logModalLabel">
										Вы хотите изменить логин?
									</h5>
								</div>
								<div className="modal-body">
									<h3 className="modal-login-header">{`Ваш логин ${id}`}</h3>
									<p className="modal-login-text">
										Логин используется при входе в профиль и его можно увидеть в
										ссылке на вашу визитку.
									</p>
									<p className="modal-login-text">
										В дальнейшем логин нельзя будет изменить.
									</p>
								</div>
								<div className="modal-footer_row">
									<button
										type="button"
										className="btn btn-success btn-block"
										onClick={() => {
											setStep(2);
										}}
									>
										Да
									</button>
									<button
										type="button"
										className="btn btn-danger btn-block"
										onClick={handleSubmit}
									>
										Нет
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{step === 2 && (
				<div>
					<button
						type="button"
						id={'logmodalbtn2'}
						className="btn btn-primary d-none"
						data-toggle="modal"
						data-target="#logModal2"
					></button>
					<div
						className="modal fade"
						id="logModal2"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="logModalLabel2"
						aria-hidden="true"
					>
						<div className="modal-dialog login-modal-one" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="logModalLabel">
										Изменить логин
									</h5>
								</div>
								<div className="modal-body">
									<input
										type="text"
										className="form-control mt-2 mb-2"
										id="inputText3"
										placeholder="Введите новый логин"
										value={value}
										onChange={handleValue}
										// TODO протестировать
										onKeyUp={() => setValue(value.replace(/[^A-Za-z0-9_]/, ''))}
									/>
									<span>Можно использовать только латиницу, цифры и «_»</span>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-danger btn-block"
										onClick={handleSubmit}
									>
										Сохранить
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LogModal;
