import React, {useState} from 'react';
import {createFields} from "../../http/fieldsApi";
import { getColors } from '../../utils/getColors';


const FormMessengers = () => {

    const [title, setTitle] = useState('whatsapp');
    const [value, setValue] = useState('');
    const [link, setLink] = useState('');

    function handleRadio(event) {
        setTitle(event.target.children[0].id)
    }
    function handleValue(event) {
        setValue(event.target.value)
    }
    function handleLink(event) {
        setLink(event.target.value)
    }
    async function handleSubmit() {
        if (link[0]!== '+' || link[1]!== '7' || link.length !==12) {
            alert('проверьте введенные данные')
            return null;
        }
        if (title === 'whatsapp') {
            await createFields({
							title: title,
							value: value,
							link: 'https://wa.me/' + link,
							color_text: '#ffffff',
							color_button: getColors(title),
						});
        }
        if (title === 'viber') {
            await createFields({
							title: title,
							value: value,
							link: link,
							color_text: '#ffffff',
							color_button: getColors(title),
						});
        }
        window.location.reload();
    }
    return (
        <div className="card">
            <div className="card-header" id="heading9">
                <h5 className="mb-0">
                    <button className="btn btn-link" data-toggle="collapse"
                            data-target="#collapse9" aria-expanded="true"
                            aria-controls="collapse9">
                        Мессенджеры
                    </button>
                </h5>
            </div>
            <div id="collapse9" className="collapse" aria-labelledby="heading9"
                 data-parent="#accordion">
                <div className="card-body">
                    <form>
                        <div className="btn-group btn-group-toggle d-flex flex-wrap" data-toggle="buttons">
                            <label className="btn btn-secondary active" onClick={handleRadio}>
                                <input type="radio" name="options" id="whatsapp" />
                                whatsapp
                            </label>
                            {/*<label className="btn btn-secondary" onClick={handleRadio}>*/}
                            {/*    <input type="radio" name="options" id="viber" />*/}
                            {/*    Viber*/}
                            {/*</label>*/}
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputText2"
                                   className="col-12 col-form-label">Заголовок</label>
                            <div className="col-12">
                                <input type="text" className="form-control" id="inputText2" onChange={handleValue}
                                       placeholder="Как будет отображаться в профиле?" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputText3"
                                   className="col-12 col-form-label">Введите номер телефона начиная с +7</label>
                            <div className="col-12">
                                <input type="text" className="form-control" onChange={handleLink}
                                       id="inputText3" placeholder="+7..." />
                            </div>
                        </div>
                        <button className="btn btn-secondary" type="button" onClick={handleSubmit}>Добавить</button>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default FormMessengers;
