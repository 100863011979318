import React, {useState} from 'react';
import {createFields} from "../../http/fieldsApi";
import { getColors } from '../../utils/getColors';

const FormApp = () => {

    const [title, setTitle] = useState('playmarket');
    const [value, setValue] = useState('');
    const [link, setLink] = useState('');

    function handleRadio(event) {
        setTitle(event.target.children[0].id)
    }
    function handleValue(event) {
        setValue(event.target.value)
    }
    function handleLink(event) {
        setLink(event.target.value)
    }
    async function handleSubmit() {
        await createFields({
					title: title,
					value: value,
					link: link,
					color_text: title === 'text' ? '#000000': '#ffffff',
					color_button: getColors(title),
				});
        window.location.reload();
    }
    return (
        <div className="card">
            <div className="card-header" id="headingSeven">
                <h5 className="mb-0">
                    <button className="btn btn-link" data-toggle="collapse"
                            data-target="#collapseSeven" aria-expanded="true"
                            aria-controls="collapseSeven">
                        Приложение
                    </button>
                </h5>
            </div>
            <div id="collapseSeven" className="collapse " aria-labelledby="headingSeven"
                 data-parent="#accordion">
                <div className="card-body">
                    <form>
                        <div className="btn-group btn-group-toggle d-flex flex-wrap" data-toggle="buttons">
                            <label className="btn btn-secondary active" onClick={handleRadio}>
                                <input type="radio" name="options" id="playmarket" />
                                play market
                            </label>
                            <label className="btn btn-secondary" onClick={handleRadio}>
                                <input type="radio" name="options" id="appstore" />
                                appstore
                            </label>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputText2"
                                   className="col-12 col-form-label">Заголовок</label>
                            <div className="col-12">
                                <input type="text" className="form-control" id="inputText2" onChange={handleValue}
                                       placeholder="Как будет отображаться в профиле?" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputText3"
                                   className="col-12 col-form-label">Ссылка</label>
                            <div className="col-12">
                                <input type="text" className="form-control" onChange={handleLink}
                                       id="inputText3" placeholder="Вставьте ссылку на приложение" />
                            </div>
                        </div>
                        <button className="btn btn-secondary" type="button" onClick={handleSubmit}>Добавить</button>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default FormApp;
