import React, { useState, useRef } from 'react';
import { createFields } from '../../http/fieldsApi';
import { Editor } from '@tinymce/tinymce-react';

const FormText = () => {
	const editorRef = useRef(null);
	const [value, setValue] = useState();

	function handleValue(editorState) {
		setValue(editorState);
	}
	async function handleSubmit() {
		await createFields({ title: 'text', value: value, link: '' });
		window.location.reload();
	}

	return (
		<div className="card">
			<div className="card-header" id="heading4">
				<h5 className="mb-0">
					<button
						className="btn btn-link collapsed"
						data-toggle="collapse"
						data-target="#collapse4"
						aria-expanded="false"
						aria-controls="collapse4"
					>
						Текстовое поле
					</button>
				</h5>
			</div>
			<div
				id="collapse4"
				className="collapse"
				aria-labelledby="heading4"
				data-parent="#accordion"
			>
				<div className="card-body">
					<form>
						<label htmlFor="inputText3" className="col-12 col-form-label">
							Он будет отображаться у вас на странице
						</label>
						<div className="col-12">
							<Editor
								apiKey={process.env.TINYMCE_APIKEY}
								onEditorChange={handleValue}
								onInit={(evt, editor) => (editorRef.current = editor)}
								initialValue="<p>Введите текст</p>"
								value={value}
								init={{
									height: 500,
									menubar: false,
									branding: false,
									toolbar:
										'undo redo | formatselect | ' +
										'bold italic underline | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat',
									content_style:
										'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
								}}
							/>
						</div>
						<button
							className="btn btn-secondary"
							type="button"
							onClick={handleSubmit}
						>
							Добавить
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default FormText;
