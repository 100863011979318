import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getQr, partialUpdateCard, retrieveCard } from '../http/fieldsApi';

import { observer } from 'mobx-react-lite';
import Modal from '../components/Modal';
import Nickname from '../components/Nickname';
import Avatar from '../components/Avatar';
import { Context } from '../index';
import Fields from '../components/Fields';
import NoWay from './NoWay';
import ModalOpen from '../components/ModalOpen';
import { getId, getUserPhone } from '../http/userAPI';
import LogModal from '../components/LogModal';
import { vCardSave } from '../utils/vCard';

const Profile = observer(() => {
	const { user } = useContext(Context);
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [card, setCard] = useState('');
	const [flag, setFlag] = useState(false);
	const [one, setOne] = useState('');
	const [two, setTwo] = useState('');
	const [qrimg, setQrimg] = useState('');

	const createVCard = async () => {
		const data = await getUserPhone(card.id).then(result => result).catch(() => ({ phone: '', image: '' }));
		vCardSave(card, data.phone, data.image);
	}

	useEffect(() => {
		retrieveCard(id)
			.then((data) => rebase(data))
			.finally(() => setLoading(false));
		setOne(card.color_one);
		setTwo(card.color_two);
	}, [id, card.color_one, card.color_two]);

	function rebase(data) {
		setCard(data);
		setOne(data.color_one);
		setTwo(data.color_two);
	}

	const changeUserChangedLogin = () => {
		setCard((prev) => ({ ...prev, has_changed_username: true }));
	}

	if (!flag) {
		var isAuth = user.isAuth;
		if (user.id === card.page_path) {
			user.setIsAuth(true);
			isAuth = user.isAuth;
		} else {
			user.setIsAuth(false);
			isAuth = user.isAuth;
		}
	} else {
		user.setIsAuth(false);
		isAuth = user.isAuth;
	}

	function preview() {
		setFlag(!flag);
		user.setIsAuth(!flag);
		isAuth = user.isAuth;
	}

	async function qrpreview() {
		let response = await getQr(id);
		setQrimg(response);
	}

	if (loading) {
		return (
			<div className="w-100 min-vh-100 d-flex align-items-center justify-content-center">
				Секундочку....
			</div>
		);
	}

	function dnd(isAuth) {
		if (isAuth) {
			return <Fields id={id} fields={card.fields} isAuth={true} />;
		} else {
			return <Fields id={id} fields={card.fields} isAuth={false} />;
		}
	}

	function debounce(f, ms = 200) {
		let isCooldown = false;
		return function () {
			if (isCooldown) return;
			f.apply(this, arguments);
			isCooldown = true;
			setTimeout(() => (isCooldown = false), ms);
		};
	}

	function handleChangeOne() {
		let el = document.getElementById('color-one');
		setOne(el.value);
	}

	function handleChangeTwo() {
		let el = document.getElementById('color-two');
		setTwo(el.value);
	}

	async function handleSubmit() {
		let id = await getId();
		await partialUpdateCard(id, { color_one: one, color_two: two });
		setCard(prev => ({ ...prev, color_one: one, color_two: two }))
	}

	

	return card ? (
		<div>
			{card.redirect_url !== ''
				? document.location.replace(card.redirect_url)
				: null}
			{/*смена логина для первого захода юзера*/}
			{!card.has_changed_username && isAuth && (
				<LogModal
					setChangedLogin={changeUserChangedLogin}
					id={id}
					name={card.displayed_name}
				/>
			)}
			{/*шапка сайта*/}
			<div
				className="container user-info pt-1 pb-4"
				style={{
					background: 'linear-gradient(' + one + '80,' + two + '80)',
				}}
			>
				<div className="row d-flex flex-column align-items-center">
					{/*аватар юзера*/}
					<Avatar props={card} />
					{/*qr юзера*/}
					{isAuth ? (
						<div>
							<button
								className="preview qrpreview"
								onClick={qrpreview}
								data-toggle="modal"
								data-target="#qrModalCenter"
							>
								<img src="/image/qr-code.svg" alt="" />
							</button>
							<div
								className="modal fade"
								id="qrModalCenter"
								tabIndex="-1"
								role="dialog"
								aria-labelledby="qrModalCenterTitle"
								aria-hidden="true"
							>
								<div
									className="modal-dialog modal-dialog-centered"
									role="document"
								>
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title" id="qrModalLongTitle">
												Отсканируйте изображение
											</h5>
											<button
												type="button"
												className="close"
												data-dismiss="modal"
												aria-label="Close"
											>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body">
											<img src={qrimg} alt="" />
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="btn btn-secondary"
												data-dismiss="modal"
											>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}
					{/*никнейм юзера*/}
					<Nickname props={card} />
					{/*вид юзера глазами других пользователей*/}
					{(isAuth || flag) && (
						<button className="preview" onClick={preview}>
							<img
								src={!flag ? '/image/eyes.svg' : '/image/eyesfalse.svg'}
								alt=""
							/>
						</button>
					)}
				</div>
			</div>
			{/*Возможность менять градиент юзера*/}
			<div className='controls-container'>
				{isAuth && !flag ? (
					<div className="input-color d-flex justify-content-center align-items-center">
						<input
							id="color-one"
							value={one}
							className="color-input-field"
							type="color"
							onChange={() => debounce(handleChangeOne())}
						/>
						<input
							id="color-two"
							value={two}
							className="color-input-field"
							type="color"
							onChange={() => debounce(handleChangeTwo())}
						/>
						{/* TODO протестировать */}
						<button
							disabled={one === card.color_one && two === card.color_two}
							className="btn-change-color"
							onClick={() => handleSubmit()}
						>
							сохранить
						</button>
					</div>
				) : null}
				<button className='vcard-button' onClick={() => createVCard()}>сохранить контакт</button>
			</div>
			<div className="container main mt-3">
				{card && dnd(user.isAuth)}
				<div className="row d-flex flex-column align-items-center">
					{isAuth && <Modal />}
				</div>
				{card.fields?.map((element) => (
					<ModalOpen key={element.id} props={element} />
				))}
			</div>
			{card?.product_by && <div className="d-flex Title w-100 align-items-center justify-content-center">
				product by
				<a
					rel="noopener noreferrer"
					target="_blank"
					className="ml-2 text-decoration-none"
					href=" http://touch-nfc.ru/"
				>
					touch-nfc
				</a>
			</div>}
		</div>
	) : (
		<NoWay />
	);
});

export default Profile;
