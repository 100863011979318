import React, {useContext, useState,useEffect} from 'react';
import {partialUpdateCard} from "../http/fieldsApi";
import { Context } from "../index";

import {useParams} from "react-router-dom";
import ImageModal from './ImageModal';

const Avatar = (card) => {
	const { user } = useContext(Context);
	var isAuth = user.isAuth;
	const [avatar, setAvatar] = useState(card.props.photo);
    const [srcForCrop, setSrcForCrop] = useState();
    const [croppedImage, setCroppedImage] = useState();
    const { id } = useParams();

	useEffect(() => {
		setAvatar(card.props.photo);
	}, [card.props.photo]);

	async function upFile() {
		var input = document.getElementById('uplodfile');
		input.click();
	}

	function onMainPhotoSelected(e) {
		e.preventDefault();
		if (e.target.files.length) {
			setSrcForCrop(URL.createObjectURL(e.target.files[0]));
			const modalButton = document.getElementById('image-crop-modal-button');
			if (modalButton) {
				modalButton.click();
			}
		}
    }
    
    const submitImage = async () => {
        var formData = new FormData();
        formData.append('photo', croppedImage);
        await partialUpdateCard(id, formData);
        window.location.reload();
    }

	return isAuth ? (
		<div>
			<form className="element-photo">
				<img onClick={upFile} src={avatar} alt="" />
				<input type="file" id="uplodfile" onChange={onMainPhotoSelected} />
			</form>
			<button
				id="image-crop-modal-button"
				type="button"
				className="btn btn-primary"
				data-toggle="modal"
				data-target="#exampleModal"
				style={{ display: 'none' }}
			>
				Launch demo modal
            </button>
			<ImageModal
				submitImage={submitImage}
				setCroppedImage={setCroppedImage}
				avatar={srcForCrop}
			/>
		</div>
	) : (
		<div className="element-photo">
			<img src={avatar} alt="" />
		</div>
	);
};

export default Avatar;
