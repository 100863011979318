import React, { useEffect, useState, useRef } from 'react';
import { destroyFields, partialUpdateField } from '../http/fieldsApi';
import { Editor } from '@tinymce/tinymce-react';
import { getColors } from '../utils/getColors';

const ModalOpen = (element) => {
	const editorRef = useRef(null);
	const [title] = useState(element.props.title);
	const [value, setValue] = useState(element.props.value);
	const [link, setLink] = useState(element.props.link);
	const [textColor, setTextColor] = useState(element.props.color_text || "#000000");
	const [buttonColor, setButtonColor] = useState(element.props.color_button || "#ffffff");

	useEffect(() => {
		setLink(element.props.link);
	}, [element.props.link]);

	useEffect(() => {
		setValue(element.props.value);
	}, [element.props.value]);

	useEffect(() => {
		if (element.props.color_text) {
			setTextColor(element.props.color_text)
		} else {
			if (element.props.title === 'text') {
				setTextColor("#000000")
			} else {
				setTextColor("#ffffff")
			}
		}
		if (element.props.color_button) {
			setButtonColor(element.props.color_button);
		} else {
			const color = getColors(element.props.title);
			setButtonColor(color);
		}
	}, [element]);

	const handleColorChange = (e, key) => {
		if (key === 'text' && e && e.target && e.target.value) {
			setTextColor(e.target.value);
			return;
		}
		if (key === 'button' && e && e.target && e.target.value) {
			setButtonColor(e.target.value);
			return;
		}
	};

	var flag;
	if (link.startsWith('tel:')) {
		flag = 'tel:';
	} else if (link.startsWith('mailto:')) {
		flag = 'mailto:';
	}
	function handleValue(event) {
		element.props.title === 'text'
			? setValue(event)
			: setValue(event.target.value);
	}
	function handleLink(event) {
		setLink(event.target.value);
	}
	async function handleSubmit() {
		if (title === 'phone') {
			await partialUpdateField(element.props.id, {
				value: value,
				link: 'tel:' + link.replace(flag, ''),
				color_button: buttonColor,
				color_text: textColor,
			});
		} else if (title === 'email') {
			await partialUpdateField(element.props.id, {
				value: value,
				link: 'mailto:' + link.replace(flag, ''),
				color_button: buttonColor,
				color_text: textColor,
			});
		} else {
			await partialUpdateField(element.props.id, {
				value: value,
				link: link,
				color_button: buttonColor,
				color_text: textColor,
			});
		}
		window.location.reload();
	}
	async function delElement() {
		await destroyFields(element.props.id);
		window.location.reload();
	}
	return (
		<form
			className="modal fade"
			id={element.props.id}
			tabIndex="-1"
			role="dialog"
			aria-labelledby="fixModalLabel"
			aria-hidden="true"
		>
			<div
				className="col-12 d-flex align-content-center justify-content-center modal-dialog"
				role="document"
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="fixModalLabel">
							Редактирование
						</h5>
						<button
							type="button"
							id={'close' + element.props.id}
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{element.props.value && element.props.title === 'text' && (
							<Editor
								apiKey={process.env.TINYMCE_APIKEY}
								onEditorChange={handleValue}
								onInit={(evt, editor) => (editorRef.current = editor)}
								value={value}
								init={{
									height: 300,
									menubar: false,
									branding: false,
									toolbar:
										'undo redo | formatselect | ' +
										'bold italic underline | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat',
									content_style:
										'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
								}}
							/>
						)}
						{element.props.value && element.props.title !== 'text' && (
							<div>
								<p>Заголовок</p>
								<input
									type="text"
									placeholder="Как будет отображаться в профиле"
									value={value}
									onChange={handleValue}
								/>
							</div>
						)}
						{element.props.link && (
							<div className="mt-3">
								<p>Ссылка</p>
								<input
									type="text"
									placeholder="Введите ссылку"
									value={link.replace(flag, '')}
									onChange={handleLink}
								/>
							</div>
						)}
						<div className="colors-container">
							<div className="colors-container-item">
								<p>Фон кнопки:</p>
								<input
									className="color-input-field"
									onChange={(e) => handleColorChange(e, 'button')}
									value={buttonColor}
									type="color"
								/>
							</div>
							<div className="colors-container-item">
								<p>Цвет текста:</p>
								<input
									className="color-input-field"
									onChange={(e) => handleColorChange(e, 'text')}
									value={textColor}
									type="color"
								/>
							</div>
						</div>
						<button
							type="button"
							onClick={delElement}
							className="btn mt-3 btn-danger btn-block"
						>
							Удалить элемент
						</button>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={handleSubmit}
							className="btn btn-success btn-block btn-save-changes"
						>
							Сохранить изменения
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ModalOpen;
