export const getColors = (title) => {
	if (!title) {
		return '#87CEFA';
	}
	switch (title) {
		case 'instagram':
			return '#bf0662';
		case 'whatsapp':
			return '#25d366';
		case 'viber':
			return '#7360f2';
		case 'twitter':
			return '#1da1f2';
		case 'vk':
			return '#2787f5';
		case 'telegram':
			return '#31a8e0';
		case 'tiktok':
			return '#black';
		case 'facebook':
			return '#4867aa';
		case 'youtube':
			return '#FF0000';
		case 'email':
			return '#5f2ed2';
		case 'map':
			return '#4cd950';
		case 'web':
			return '#e54c04';
		case 'appstore':
			return '#1c98f8';
		case 'playmarket':
			return '#01aa53';
		case 'phone':
			return '#3CB371';
		case 'text':
			return '#FFFFFF';
		default:
			return '#FFFFFF';
	}
};
